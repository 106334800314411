import * as React from "react"

export default function Mudhorobah () {
    return (
        <>
            <p>
                Bentuk kerjasama yang dimana islah memberi modal kepada penjual, dengan pengelolaan full dipegang oleh penjual dan keuntungan sesuai kesepakatan dengan minimal 30% untuk islah, 
            </p>
            <p>
                nb: dalam jangka waktu yang disepakati mau penjual rugi atau laba, penjual harus mengembalikan modal yang diberikan islah kepada penjual:)
            </p>
        </>
    )
}